// import { secretkey } from '../constants/config';
// import { AES, enc } from 'crypto-js';
// import { getEnrollmentCustomField } from 'api/customField';
// import { viewMemberProgram } from 'api/learner/program';
// import {
//   assessorIcon,
//   tutorsIcon,
//   administratorIcon,
//   learnersIcon,
//   verifierIcon,
//   openBookIcon,
//   filesIcon,
//   assignmentDarkIcon,
//   offthejobIcon,
//   subSectionIcon,
//   folderIcon,
//   coursePlayIcon,
//   resourceIcon,
//   reviewIcon,
// } from 'constants/icons';
// import ls from 'localstorage-slim';
import Log from './Log';

// ls.config.encrypt = true;
//const days = ['Sun', 'Mon', 'Tue', 'Thu', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const translate = (language) => (selector, obj) => {
  try {
    if (Object.keys(language).length > 0) {
      if (obj) {
        let arr = Object.entries(obj);
        var txt = language[selector];
        let i = 0;

        while (i < arr.length) {
          let reg = new RegExp('{{' + arr[i][0] + '}}', 'g');
          txt = txt.replace(reg, arr[i][1]);
          i++;
        }

        return txt;
      } else {
        return language[selector];
      }
    }
  } catch (error) {
    Log.error(error);
  }
};
export const arrangeList = ({ rawList, fromIndex, toIndex }) => {
  var cloneList = [...rawList];
  let fromObj = rawList[fromIndex];
  //let toObj = rawList[toIndex];
  if (fromIndex > toIndex) {
    cloneList.splice(fromIndex, 1);
    cloneList.splice(toIndex, 0, fromObj);
  } else {
    cloneList.splice(toIndex, 0, fromObj);
    cloneList.splice(fromIndex, 1);
  }

  cloneList = cloneList.map((item, index) => {
    return { ...item, priority: index };
  });

  return {
    rawList,
    unArrangeList: cloneList,
    arrangeList: cloneList,
  };
};

export const immutableMove = (arr, from, to) => {
  try {
    return arr.reduce((prev, current, idx, self) => {
      if (from === to) {
        prev.push(current);
      }
      if (idx === from) {
        return prev;
      }
      if (from < to) {
        prev.push(current);
      }
      if (idx === to) {
        prev.push(self[from]);
      }
      if (from > to) {
        prev.push(current);
      }
      return prev;
    }, []);
  } catch (error) {
    Log.error(error);
  }
};
export const rearrange = (list, from, to) => {
  try {
    let finalItem = list.find((item) => item.priority === from);
    finalItem.priority = 10000;
    if (from > to) {
      for (let priority = from - 1; priority >= to; priority--) {
        let data = list.find((item) => item.priority === priority);
        if (data) {
          data.priority++;
        }
      }
    } else {
      for (let priority = from + 1; priority <= to; priority++) {
        let data = list.find((item) => item.priority === priority);
        if (data) {
          data.priority--;
        }
      }
    }
    finalItem.priority = to;
    return list.sort((a, b) => a.priority - b.priority);
  } catch (error) {
    Log.error(error);
  }
};
export const reOrderIndex = (list, startIndex, endIndex) => {
  try {
    let indexList = list.map((item) => item.index).sort();
    return list.map((item, index) => {
      let obj = { ...item };
      obj.index = indexList[index];
      return obj;
    });
  } catch (error) {
    Log.error(error);
  }
};
export const displayFormatedDate = (mydate, txt) => {
  try {
    return ` ${months[mydate.getMonth()]} ${mydate.getDate()},${mydate.getFullYear().toString().slice(-2)}`;
  } catch (error) {
    Log.error(error);
  }
};
export const displayCommonDate = (mydate, txt) => {
  try {
    return `${months[mydate.getMonth()]} ${mydate.getDate()}, ${mydate.getFullYear().toString()}`;
  } catch (error) {
    Log.error(error);
  }
};
export const isValidUrl = (urlString) => {
  var urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
  return !!urlPattern.test(urlString);
};

export const fixInteger = (num) => {
  try {
    if (num) {
      let thenum = num.toString();
      let numIndex = thenum.indexOf('.');
      let resultNum = thenum.slice(0, numIndex + 3);
      return resultNum;
    } else {
      return '0';
    }
  } catch (error) {
    Log.error(error);
  }
};

export const activeRow = (row) => {
  try {
    let index = row.event.currentTarget.getAttribute('data-row-index');
    row.event.currentTarget.classList.add('highlight');
    let allCell = document.querySelectorAll('.rgt-cell');

    // Remove Class
    let i = 0;
    while (i < allCell.length) {
      allCell[i].classList.remove('selectedRow');
      i++;
    }

    // Add Class on selected Row
    let rowCells = document.querySelectorAll(`.rgt-cell[data-row-index='${index}']`);

    let j = 0;
    while (j < rowCells.length) {
      rowCells[j].classList.add('selectedRow');
      j++;
    }
  } catch (error) {
    Log.error(error);
  }
};

export const pagina = (obj) => {
  try {
    var { total, page, limit } = obj;
    //var next = false;
    var increment = 0;
    //var totalPage = 0;

    var remainder = total % limit;
    var tot = 0;
    if (remainder > 0) {
      increment = 1;
    }

    tot = total / limit + increment;

    tot = parseInt(tot);

    return {
      prev: page > 1,
      next: page < tot === true,
    };
  } catch (error) {
    Log.error(error);
  }
};
export const convertTimeToSecs = (hrs, mins, secs) => {
  try {
    let h = parseInt(hrs) * 60 * 60;
    let m = parseInt(mins) * 60;
    let s = parseInt(secs);
    let total = h + m + s;
    return total;
  } catch (error) {
    Log.error('Error on convertTimeToSecs :', error);
  }
};
// export const showTypeIcon = (iconName) => {
//   switch (iconName) {
//     case 'Section':
//       return folderIcon;
//     case 'subSection':
//       return subSectionIcon;

//     case 'book':
//       return openBookIcon;

//     case 'file':
//       return filesIcon;

//     case 'ResourceComponent':
//       return resourceIcon;

//     case 'CourseComponent':
//       return coursePlayIcon;

//     case 'UnitComponent':
//       return assignmentDarkIcon;
//       break;
//     case 'OTJComponent':
//       return offthejobIcon;
//     case 'ReviewComponent':
//       return reviewIcon;
//     default:
//       return filesIcon;
//   }
// };
export const covertSecsToTimeData = (time) => {
  try {
    let remainTime = time;

    let hrs = parseInt(time / (60 * 60));
    remainTime -= hrs * 60 * 60;

    let mins = parseInt(remainTime / 60);
    remainTime -= mins * 60;

    let secs = remainTime.toString();

    let hrsData = hrs < 10 ? '0' + hrs : hrs;
    let secsData = secs < 10 ? '0' + secs : secs;
    let minsData = mins < 10 ? '0' + mins : mins;
    return { hrs: hrsData, mins: minsData, secs: secsData };
  } catch (error) {
    Log.error('Error on covertSecsToTime :', error);
  }
};
export const covertSecsToTime = (time) => {
  try {
    let remainTime = time;

    let hrs = parseInt(time / (60 * 60));
    remainTime -= hrs * 60 * 60;
    let hrsLabel = hrs < 10 ? '0' + hrs : hrs;

    hrsLabel = hrs > 0 ? hrsLabel + ':' : '';

    let mins = parseInt(remainTime / 60);
    remainTime -= mins * 60;
    let minsLabel = mins < 10 ? '0' + mins : mins;

    let secs = remainTime;
    let secsLabel = secs < 10 ? '0' + secs : secs;
    return hrsLabel + minsLabel + ':' + secsLabel;
  } catch (error) {
    Log.error('Error on covertSecsToTime :', error);
  }
};
export const setPercentageVariant = (progressPercent) => {
  try {
    let percentage = parseInt(progressPercent);
    if (percentage > 0 && percentage < 50) {
      return 'danger';
    } else if (percentage >= 50 && percentage < 100) {
      return 'info';
    } else if (percentage === 100) {
      return 'success';
    }
  } catch (error) {
    Log.error(error);
  }
};

// export const encryptData = (data) => {
//   try {
//     let normalEncrypt = AES.encrypt(data, secretkey).toString();
//     let noSlash = normalEncrypt.replace(/\//g, 'ø');
//     return noSlash;
//   } catch (error) {
//     Log.error(error);
//   }
// };
// export const decryptData = (data) => {
//   try {
//     let rawData = data.replace(/ø/g, '/');
//     let bytes = AES.decrypt(rawData, secretkey);
//     const decryptedUserId = bytes.toString(enc.Utf8);
//     return decryptedUserId;
//   } catch (error) {
//     Log.error(error);
//   }
// };

// export const checkBeforeAPI = () => {
//   try {
//     let expireDate = ls.get('tokenExpire');
//     let isSuperAdmin = ls.get('role') === 'superadmin';
//     let currentDate = new Date();
//     if (currentDate > new Date(expireDate) && isSuperAdmin) {
//       localStorage.clear();
//       window.location.href = '/logout';
//       return false;
//     }
//   } catch (error) {
//     Log.error(error);
//   }
// };

// export const getUserIconFromRoles = (roles) => {
//   try {
//     if (!Array.isArray(roles)) {
//       return '';
//     }
//     const isAdmin = roles.find((role) => role.identifier == 'admin');
//     if (isAdmin) {
//       return administratorIcon;
//     } else {
//       const isTutor = roles.find((role) => role.identifier == 'tutor');
//       if (isTutor) {
//         return tutorsIcon;
//       } else {
//         const isAssessor = roles.find((role) => role.identifier == 'assessor');
//         if (isAssessor) {
//           return assessorIcon;
//         } else {
//           const isVerifier = roles.find((role) => role.identifier == 'iqv' || role.identifier == 'eqv');
//           if (isVerifier) {
//             return verifierIcon;
//           } else {
//             return learnersIcon;
//           }
//         }
//       }
//     }
//   } catch (error) {
//     Log.error(error);
//   }
// };

// export const getUserIconFromRoleIdentifier = (role) => {
//   try {
//     switch (role) {
//       case 'admin':
//         return administratorIcon;

//       case 'tutor':
//         return tutorsIcon;

//       case 'assessor':
//         return assessorIcon;

//       case 'iqv':
//         return verifierIcon;

//       case 'eqv':
//         return verifierIcon;

//       default:
//         return learnersIcon;
//     }
//   } catch (error) {
//     Log.error(error);
//   }
// };

export const getPlainTextFromHtmlText = (htmlText) => {
  let div = document.createElement('div');
  div.innerHTML = htmlText;
  return div.textContent;
  // if (!htmlText) {
  //   return htmlText;
  // }
  // return htmlText?.replace(/<[^>]+>/g, '');
};

export const groupBy = (data, field) => {
  if (!Array.isArray(data)) {
    return data;
  }
  return data?.reduce(function (r, a) {
    r[a[field]] = r[a[field]] || [];
    r[a[field]].push(a);
    return r;
  }, Object.create(null));
};
export const capitalizeWord = (word) => {
  if (!word) return word;
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const truncateParagraph = (content, screenWidth, showMore) => {
  if (!content) return content;
  let MAX_LENGTH = 120;

  if (screenWidth >= 1500) {
    MAX_LENGTH = 463;
  }
  if (screenWidth >= 1100 && screenWidth < 1500) {
    MAX_LENGTH = 300;
  }

  if (screenWidth < 1100 && screenWidth >= 600) {
    MAX_LENGTH = 200;
  }

  if (content.length < MAX_LENGTH || !showMore) return { content, more: false };
  return { content: content.slice(0, MAX_LENGTH) + '...', more: true };
};
export const sortElements = (fields) => {
  let indexCol = [];
  fields.forEach((element) => {
    indexCol.push(element.index);
  });
  let arrangeIndex = indexCol.sort();
  let arrangeFields = [];
  // while (i < arrangeIndex.length) {
  //   arrangeFields.push(fields.find((field) => parseInt(field.index) === arrangeIndex[i]));
  //   i++;
  // }
  for (let i = 0; i < arrangeIndex.length; i++) {
    arrangeFields.push(fields.find((field) => parseInt(field.index) === arrangeIndex[i]));
  }
  return arrangeFields;
};

export const fillFormData = (formData, allData) => {
  try {
    let obj = allData.find((item) => item.id === formData.id);
    let formDataObj = JSON.parse(JSON.stringify(formData));
    formDataObj.value = obj?.value ? obj.value : '';

    return formDataObj;
  } catch (error) {
    Log.error(error);
  }
};

export const synArrayData = (oldData, newData) => {
  try {
    let data = [...oldData];
    // If newData is Array
    if (Array.isArray(newData)) {
      newData.forEach((ele) => {
        let oldEle = oldData.find((olditem) => olditem.id === ele.id);
        if (oldEle) {
          let indexData = data.indexOf(oldEle);
          data[indexData] = { ...data[indexData], ...ele };
        } else {
          data.push(ele);
        }
      });
    } else if (typeof newData === 'object') {
      // If newData is Object
      let oldEle = oldData.find((olditem) => olditem.id === newData.id);
      if (oldEle) {
        let indexData = data.indexOf(oldEle);
        data[indexData] = { ...data[indexData], ...newData };
      } else {
        data.push(newData);
      }
    }
    return data;
  } catch (error) {
    Log.error(error);
  }
};
const getTotalChars = (obj) => {
  try {
    let totalChar = 0;
    if (obj.type === 'textarea') {
      let ele = document.createElement('div');
      ele.innerHTML = obj?.value;
      totalChar = ele?.textContent.trim().length;
    } else {
      totalChar = obj?.value?.length;
    }
    return totalChar;
  } catch (error) {
    Log.error(error);
  }
};
const arrangeInDate = (rawData) => {
  let dateList = rawData.map((item) => {
    let data = { ...item };

    var mydate = new Date();
    mydate.setFullYear(item._id.year);
    mydate.setMonth(item._id.month - 1);
    mydate.setDate(1);
    data.id = mydate.getTime();
    data.date = mydate;
    return data;
  });

  let sortDate = dateList.sort((a, b) => Number(a.id) - Number(b.id));
  return sortDate.map((item) => {
    let obj = { ...item };
    delete obj.date;
    delete obj.id;
    return obj;
  });
};
export const convertRawChatData = (data) => {
  var rawData = arrangeInDate(data);
  var payment_types = ['one_time_payment', 'Cancelled', 'Initiate Humm Process', 'payment_plan', 'Refunded', 'Ordered - Humm'];
  var paymentLabel = {
    one_time_payment: 'One Time Payment',
    'Initiate Humm Process': 'Initiate Humm Process',
    Cancelled: 'Cancelled',
    payment_plan: 'Payment Plan',
    Refunded: 'Refunded',
    'Ordered - Humm': 'Ordered - Humm',
  };
  var totalmonths = [];
  let paymentsOnly = ['one_time_payment', 'Ordered - Humm', 'payment_plan'];
  let deductionOnly = ['Refunded', 'Cancelled', 'Initiate Humm Process'];
  var monthss = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // Collecting all Payment Types
  rawData.map((item) =>
    item.payments.map((payment) => {
      if (!payment_types.includes(payment.payment_type)) {
        payment_types.push(payment.payment_type);
      }
    }),
  );

  // totalMonths Collections

  rawData.map((item) => {
    totalmonths.push(item._id);
  });

  let mainData = [];
  let totalGAmount = [];

  let netTotal = 0;

  payment_types.map((item, index) => {
    try {
      let obj = {
        type: 'stackedColumn',
        name: paymentLabel[item] ? paymentLabel[item] : item,
        showInLegend: true,
        yValueFormatString: '#,###',
      };
      var dataPoints = [];

      var eachOrder = 0;
      // Refine Amount
      rawData.map((raw) => {
        var eachTotal = 0;
        let ob = raw.payments.find((pay) => pay.payment_type === item);
        let totalPayment = raw.payments
          .filter((item) => item.total_price)
          .filter((obj) => paymentsOnly.includes(obj.payment_type))
          .map((price) => price.total_price)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        if (index === 0) {
          eachTotal = +totalPayment;
        }

        let totalOrder = raw.payments
          .filter((item) => item.total_orders)
          .filter((obj) => paymentsOnly.includes(obj.payment_type))
          .map((price) => price.total_orders)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        eachOrder = +totalOrder;

        // Duduction Amount
        let deductionAmount = raw.payments
          .filter((item) => item.total_price)
          .filter((obj) => deductionOnly.includes(obj.payment_type))
          .map((price) => price.total_price)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        let deductionOrder = raw.payments
          .filter((item) => item.total_orders)
          .filter((obj) => deductionOnly.includes(obj.payment_type))
          .map((price) => price.total_orders)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        if (index === 0) {
          eachTotal -= deductionAmount;
          totalGAmount.push(eachTotal);
        }

        eachOrder -= deductionOrder;

        let aa = {};

        if (ob) {
          let price = 0;
          price = ob.total_price;
          aa.y = price;
          aa.order = ob.total_orders;
          aa.label = monthss[raw._id.month - 1] + '(' + (totalPayment - deductionAmount) + ')';
        } else {
          aa.y = 0;
          aa.label = monthss[raw._id.month - 1] + '(0)';
          aa.order = eachOrder;
        }
        dataPoints.push(aa);
      });
      obj.dataPoints = dataPoints;
      // var ppp = rawData.map((raw) => {
      //   let ob = raw.payments.find((pay) => pay.payment_type === item);

      //   return ob;
      // });

      obj.name = `${obj.name}`;
      // obj.name = `${obj.name} ${obj.order}`;
      mainData.push(obj);
      Log.info(eachOrder);
    } catch (error) {
      Log.error(error);
    }
  });

  return { mainData, netTotal: totalGAmount.reduce((a, b) => a + b) };
};
// export const fetchProgramCustomFields = async (programId) => {
//   try {
//     let memberDetail = await viewMemberProgram({ memberProgramId: programId });
//     let { custom_fields, program, member } = { ...memberDetail.data.data };

//     custom_fields = JSON.parse(custom_fields);
//     let result = await getEnrollmentCustomField(program.id);

//     const { data } = result;
//     var customFields1 = data.data.map((item) => {
//       let obj = { ...item };
//       let cObj = custom_fields.find((obj) => obj.id === item.id);
//       obj.value = cObj?.value ? cObj.value : '';
//       return obj;
//     });
//     // If no any custom field assign then need to view prograom detail
//     if (customFields1.length === 0) {
//       return true;
//     } else if (customFields1.length > 0) {
//       let unFilledTotalMandatory = customFields1.filter((item) => item.is_mandatory === true && getTotalChars(item) === 0).length;
//       if (unFilledTotalMandatory > 0) {
//         return false;
//       } else {
//         return true;
//       }
//     }
//   } catch (error) {
//     Log.error(error);
//   }
// };
// export const fetchProgramCustomFieldsOnce = async ({ custom_fields, program }) => {
//   try {
//     custom_fields = JSON.parse(custom_fields);
//     let result = await getEnrollmentCustomField(program.id);

//     const { data } = result;
//     var customFields1 = data.data.map((item) => {
//       let obj = { ...item };
//       let cObj = custom_fields.find((obj) => obj.id === item.id);
//       obj.value = cObj?.value ? cObj.value : '';
//       return obj;
//     });
//     // If no any custom field assign then need to view prograom detail
//     if (customFields1.length === 0) {
//       return true;
//     } else if (customFields1.length > 0) {
//       let unFilledTotalMandatory = customFields1.filter((item) => item.is_mandatory === true && getTotalChars(item) === 0).length;
//       if (unFilledTotalMandatory > 0) {
//         return false;
//       } else {
//         return false;
//       }
//     }
//   } catch (error) {
//     Log.error(error);
//   }
// };

export const formatCustomDate = () => {};
