import axios from 'axios';

import { API_URL } from 'constants/config';
import Log from 'lib/Log';

function init() {
  const options = {
    baseURL: API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let token;

  try {
    token = localStorage.getItem('adminToken');
  } catch (e) {
    Log.error(e);
  }

  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`;
  }

  return axios.create(options);
}

export default init();
