import instance from 'utils/axiosInterceptor';

export const getSchools = async (params) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/schools`, { params })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addSchool = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/add/school`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const checkSubdomain = async (subdomain) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/check/sub-domain`, { params: { subdomain } })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateSchool = async (id, payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/school/${id}`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeSchool = async (id) => {
  return new Promise((resolve, reject) => {
    return instance
      .delete(`/super/delete/school/${id}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getItems = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/items`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addItem = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/create/item`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getClass = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/classes`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addClass = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/add/class`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeClass = async (id) => {
  return new Promise((resolve, reject) => {
    return instance
      .delete(`/super/delete/class/${id}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateClass = async (id, payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/class/${id}`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getPackages = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/packages`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addPackage = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/create/package`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addPackageItems = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/add/package-item`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removePackage = async (id) => {
  return new Promise((resolve, reject) => {
    return instance
      .delete(`/super/delete/package/${id}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updatePackage = async (id, payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/package/${id}`, payload, {
        withCredentials: true,
      })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeItem = async (id) => {
  return new Promise((resolve, reject) => {
    return instance
      .delete(`/super/delete/item/${id}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateItem = async (id, payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/item/${id}`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getLogs = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/action-logs`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getStripeLogs = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/stripe-payment-logs`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getParents = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/parents`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getChildrens = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/childrens`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getTags = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/tags`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addTag = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/create/tag`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeParent = async (id) => {
  return new Promise((resolve, reject) => {
    return instance
      .delete(`/super/delete/parent/${id}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateUser = async (id, payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/user/${id}`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getSuppliers = async (params) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/suppliers`, { params })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addSupplier = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/create/supplier`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateSupplier = async (id, payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/supplier/${id}`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeSupplier = async (id) => {
  return new Promise((resolve, reject) => {
    return instance
      .delete(`/super/delete/supplier/${id}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getOrderLogs = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/orderLogs`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateOrderStatus = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/order-status`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const createUsers = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/create/user`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getUsers = async (params) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/users`, { params })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getRoles = async () => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/roles`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getInstallment = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/get/installment/${payload}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getPaymentPlan = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/get/paymentplan/${payload}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getEmails = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/list/emaillogs`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const downloadSchoolList = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/export/schools`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const downloadClassList = async (filter) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/export/classes`, filter)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const downloadItemList = async (filter) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/export/items`, filter)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const downloadPackageList = async (filter) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/export/packages`, filter)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const downloadParentList = async (filter) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/export/parents`, filter)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const downloadStudentsList = async (filter) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/export/students`, filter)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const downloadSupplierList = async (filter) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/export/suppliers`, filter)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const downloadOrderList = async (filter) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/export/orders`, filter)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const addVoucherCode = async (payload) => {
  /*
  code, value, is_percentage, school, is_active;
  */
  try {
    return new Promise((resolve, reject) => {
      return instance
        .post(`/super/add/voucher`, payload)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    return error.response;
  }
};
export const editVoucher = async ({ id, ...payload }) => {
  try {
    return new Promise((resolve, reject) => {
      return instance
        .patch(`/super/edit/voucher/${id}`, payload)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    return error.response;
  }
};

export const getVoucherList = async (id, payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/${id}/vouchers`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const updateVoucherStatus = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/voucher-status`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const deleteVoucher = async (id) => {
  return new Promise((resolve, reject) => {
    return instance
      .delete(`super/delete/voucher/${id}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getPayments = async (params) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/orders/count`, { params })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getOrdersByMonths = async (params) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/orders-types/count`, { params })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getSchoolEmail = async (params) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/get-schoolEmail`, { params })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const setSchoolEmail = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/set-schoolEmail`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getPreviewContent = async (data) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/preview-email`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const reArragePackage = async (data) => {
  /* Payload ; {destination_priority, package_id} */
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/rearrange-package`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

// school reporting
export const addReporting = async (data) => {
  return new Promise((resolve, reject) => {
    return instance
      .put(`/super/school-order-export`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getReporting = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/school-order-export/${payload}`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getSchoolOrderQuotation = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/school-order-quotation`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateSchoolOrderQuotationStatus = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/school-order-quotation-status`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateSchoolOrderQuotationFile = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/update/school-order-quotation-file`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getAllSkuCode = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/sku-codes`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addCaseAttach = async (data) => {
  return new Promise((resolve, reject) => {
    return instance
      .post(`/super/add-case`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getAllCaseAttachRate = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/sku-codes`, { params: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeCaseAttachRate = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .delete(`/super/delete/case`, { data: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getRefundRequests = async (query) => {
  return new Promise((resolve, reject) => {
    return instance
      .get(`/super/list/refund-requests`, { params: query })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const approveRefundRequests = async (payload) => {
  return new Promise((resolve, reject) => {
    return instance
      .patch(`/super/approveRefund`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
