import CustomFloatingLabel from 'components/CustomFloatingLabel';
import { additionIcon } from 'constants/icons';
import { useState } from 'react';
import { Form, Button } from 'react-myoneui';

export default function CreateNewItems({ onAdd, setAddedValue }) {
  const [isCreating, setIsCreating] = useState(false);
  const [value, setValue] = useState('');

  const handleAddClick = () => {
    setIsCreating(true);
  };

  const handleSaveClick = () => {
    setIsCreating(false);
    onAdd({ name: value });
    setAddedValue(value);
  };

  return (
    <div className="create-new-items-container">
      {!isCreating ? (
        <span role="button" className="add-create-title" onClick={handleAddClick}>
          {additionIcon} Add Heading
        </span>
      ) : (
        <div className="create-new-content">
          <CustomFloatingLabel controlId="headingName" label="Heading name">
            <Form.Control type="text" placeholder="Heading name" required name="name" onChange={(e) => setValue(e.target.value)} />
            <Form.Control.Feedback type="invalid">Please type your Heading name here.</Form.Control.Feedback>
          </CustomFloatingLabel>
          <Button variant="primary" onClick={handleSaveClick}>
            <div className="d-flex align-items-center gap-2">Save</div>
          </Button>
        </div>
      )}
    </div>
  );
}
