import * as React from 'react';
import { useState, useEffect } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import { Row, Col, FloatingLabel, Form } from 'react-myoneui';
import DropdownCommon from '../../../components/DropdownCommon';
import SnackBar from '../../../components/SnackBar';
import DateTimePicker from 'react-datetime-picker';

import { clearFormIcon, filterIcon } from '../../../constants/icons';
import { getClass, getSchools, getSuppliers } from '../../../api/admin';
import { getPackages } from '../../../api/admin';
import { ORDER_STATUS_TYPE } from '../../../constants/orderStatus';
import { INSTALLMENT_PAYMENT, ONE_TIME_PAYMENT, PAYMENT_PLAN, MONTHLY_PAYMENT_PLAN } from '../../../constants/paymentType';
import CustomCheckbox from '../../../components/school-component/CustomCheckbox';

const ITEM_TYPE = [
  { id: 'Hardware', name: 'Hardware' },
  { id: 'Software', name: 'Software' },
];

const PAYMENT_TYPE_LIST = [
  { id: ONE_TIME_PAYMENT, name: 'One Time Payment' },
  // { id: INSTALLMENT_PAYMENT, name: 'Installment' },
  { id: PAYMENT_PLAN, name: 'Olive Payment Plan' },
  { id: MONTHLY_PAYMENT_PLAN, name: 'Humm Finance Payment' },
];

function FilterOffcanvas(props) {
  const {
    triggerTitle,
    applyFilter,
    resetFilter,
    updateResetFilter,
    showType = false,
    showClass = false,
    showOrderStatusType = false,
    showPackage = false,
    showSupplier = false,
    showSchool = true,
    showPaymentType = false,
    showParents,
    showDateRange,
  } = props;

  const [show, setShow] = useState(false);
  const [school, setSchool] = useState('');
  const [schoolClass, setSchoolClass] = useState('');
  const [schoolpackage, setSchoolPackage] = useState('');
  const [type, setType] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [supplier, setSupplier] = useState('');
  const [schoolList, setSchoolList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [paymentType, setPaymentType] = useState('');
  const [withOutOrders, setWithOutOrders] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [activeClass, setActiveClass] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    if (resetFilter) {
      setSchool('');
      setSchoolClass('');
      setType('');
      setSchoolPackage('');
      setOrderStatus('');
      setSupplier('');
      setPaymentType('');
      setWithOutOrders(false);
      setFromDate('');
      setToDate('');
      applyFilter();
      updateResetFilter();
    }
  }, [resetFilter]);

  useEffect(() => {
    const fetchSchool = async () => {
      try {
        const {
          data: { data },
        } = await getSchools({ perPage: 1000 });
        const schools = data.schoolList.map((school) => {
          return { id: school.id, name: school.name, code: school.code };
        });
        setSchoolList(schools);
      } catch (error) {
        setSchoolList([]);
      }
    };
    if (showSchool) {
      fetchSchool();
    }
  }, [showSchool]);

  useEffect(() => {
    if (showClass && school?.id) {
      const fetchClass = async () => {
        try {
          setClassList([]);
          const {
            data: { data },
          } = await getClass({ perPage: 1000, schoolId: school?.id });
          const schoolClasses = data.classes.classList.map((schoolClass) => {
            return { id: schoolClass.id, name: schoolClass.name };
          });
          setClassList(schoolClasses);
          setSchoolClass('');
        } catch (error) {
          setClassList([]);
        }
      };
      fetchClass();
    }
  }, [school, showClass]);

  useEffect(() => {
    if (showPackage && school?.id) {
      const fetchPackage = async () => {
        try {
          setPackageList([]);
          const {
            data: { data },
          } = await getPackages({ perPage: 9999, school: school?.id });

          const schoolPkg =
            data?.packages?.packageList?.map((schoolPackages) => {
              return { id: schoolPackages.id, name: schoolPackages.name };
            }) || [];

          setPackageList(schoolPkg);
          setSchoolPackage('');
        } catch (error) {
          setPackageList([]);
        }
      };
      fetchPackage();
    }
  }, [school, showPackage]);

  useEffect(() => {
    if (showSupplier) {
      const fetchSuppliers = async () => {
        try {
          const {
            data: { data },
          } = await getSuppliers({ perPage: 9999 });
          const suppliers = data.supplierList.map((sup) => {
            return { id: sup.id, name: sup.name };
          });
          setSupplierList(suppliers);
        } catch (error) {
          setSupplierList([]);
        }
      };
      fetchSuppliers();
    }
  }, [showSupplier]);

  const handleShow = () => {
    setShow(true);
  };
  const offCanvasKeyDown = (e) => e.stopPropagation();

  const clearForm = () => {
    setSchool('');
    setType('');
    setSchoolClass('');
    setSchoolPackage('');
    setOrderStatus('');
    setSupplier('');
    setPaymentType('');
    setWithOutOrders(false);
    setFromDate('');
    setToDate('');
  };

  const closeOffcanvas = () => {
    setShow(false);
  };

  return (
    <>
      <a onClick={handleShow} href="#" className="filter-icon" type="button">
        {filterIcon}
        <span>{triggerTitle}</span>
      </a>
      <Offcanvas show={show} onHide={closeOffcanvas} placement="end" onKeyDown={offCanvasKeyDown}>
        <Offcanvas.Header closeButton backButton>
          <Offcanvas.Title>
            Apply Filter
            <p className="help-text w-75">Enter the name and description of the favourite report that all users will be able to access</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {showOrderStatusType && <DropdownCommon className="mb-4" name="Status" defaultValue={orderStatus} items={ORDER_STATUS_TYPE} label="Status" onChange={(data) => setOrderStatus(data)} />}

          {showPaymentType && (
            <DropdownCommon readOnly={true} className="mb-4" name="Type" defaultValue={paymentType} items={PAYMENT_TYPE_LIST} label="Payment Type" onChange={(data) => setPaymentType(data)} />
          )}

          {showSchool && <DropdownCommon className="mb-4" name="Type" defaultValue={school} items={schoolList} label="School" onChange={(data) => setSchool(data)} dropCode={true} />}

          {showParents && (
            <div className="py-2">
              <CustomCheckbox checked={withOutOrders} onCheckboxChange={() => setWithOutOrders(!withOutOrders)}>
                <strong>without order</strong>
              </CustomCheckbox>
            </div>
          )}

          {showClass && school?.id && classList?.length > 0 && (
            <DropdownCommon className="mb-4" name="Type" defaultValue={schoolClass} items={classList} label="Class" onChange={(data) => setSchoolClass(data)} />
          )}

          {showPackage && school?.id && packageList?.length > 0 && (
            <DropdownCommon className="mb-4" name="Package" defaultValue={schoolpackage} items={packageList} label="Package" onChange={(data) => setSchoolPackage(data)} />
          )}

          {showType && <DropdownCommon className="mb-4" name="Type" defaultValue={type} items={ITEM_TYPE} label="Type" onChange={(data) => setType(data)} />}

          {showSupplier && <DropdownCommon className="mb-4" name="Type" defaultValue={supplier} items={supplierList} label="Supplier" onChange={(data) => setSupplier(data)} />}

          {showDateRange && (
            <Row>
              <Col lg={6} className="mb-4">
                <FloatingLabel className="target-end-date-label" controlId="targetFromDate" label="From">
                  <Form.Control type="text" placeholder="Date" />
                  <DateTimePicker
                    className={`remove-time-wrap ${activeClass}`}
                    clearIcon={null}
                    placeholder="From"
                    name="fromDate"
                    value={fromDate}
                    onChange={(value) => setFromDate(value)}
                    onBlur={() => {
                      setActiveClass('');
                    }}
                    onFocus={() => {
                      setActiveClass('target-date-focus');
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col lg={6} className="mb-4">
                <FloatingLabel className="target-end-date-label" controlId="targetToDate" label="To">
                  <Form.Control type="text" placeholder="Date" />
                  <DateTimePicker
                    className={`remove-time-wrap ${activeClass}`}
                    clearIcon={null}
                    placeholder="To"
                    name="schedule_end"
                    value={toDate}
                    onChange={(value) => setToDate(value)}
                    onBlur={() => {
                      setActiveClass('');
                    }}
                    onFocus={() => {
                      setActiveClass('target-date-focus');
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          )}

          <div className="clear-form">
            <Button variant="default" onClick={() => clearForm()}>
              {clearFormIcon} Clear Form
            </Button>
          </div>
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button variant="default" onClick={closeOffcanvas}>
            Cancel
          </Button>
          <SnackBar
            applyFilter={() => {
              setShow(false);
              return applyFilter({
                school,
                type,
                schoolClass,
                schoolpackage,
                orderStatus,
                supplier,
                paymentType,
                withOutOrders,
                fromDate,
                toDate,
              });
            }}
          />
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default FilterOffcanvas;
