import { useState, forwardRef, Children } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import GridDropdown from 'components/grid-table/GridDropdown';
import { eyeIcon, checkCircleFill, crossIcon } from 'constants/icons';

const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
    </div>
  );
});

const DropdownTemplate = ({ handleView, handleApprove, handleDisApprove, enableAction }) => {
  const classNames = 'rgt-cell-inner rgt-text-truncate justify-content-end grid-action-dropdown'.trim();
  return (
    <div className={classNames} title={''}>
      <GridDropdown>
        <Dropdown.Menu as={CustomMenu} className="dropdown-menu mw-dropdown-default">
          <Dropdown.Item eventKey="1" onClick={handleView}>
            {eyeIcon} <span title="View">View</span>
          </Dropdown.Item>
          {enableAction ? (
            <>
              <Dropdown.Item eventKey="2" onClick={handleApprove}>
                {checkCircleFill} <span title="Approve">Approve</span>
              </Dropdown.Item>

              <Dropdown.Item eventKey="3" onClick={handleDisApprove}>
                {crossIcon} <span title="Disapprove">Disapprove</span>
              </Dropdown.Item>
            </>
          ) : null}
        </Dropdown.Menu>
      </GridDropdown>
    </div>
  );
};

export default DropdownTemplate;
