import React, { useEffect, useState } from 'react';

function CustomCheckbox(props) {
  const { children, onCheckboxChange, checked = false } = props;
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      setChecked(true);
    }
  }, []);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
    onCheckboxChange(!isChecked);
  };

  return (
    <label className={`custom-checkbox-label ${isChecked ? 'checked' : ''} ${props.className || ''}`}>
      <div className="checkbox-wrap">
        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} className="hide-checkbox" />
        <div className="custom-checkbox"></div>
      </div>
      {children}
    </label>
  );
}

export default CustomCheckbox;
