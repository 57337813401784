export const ORDERED = 'Ordered';
export const PACKAGING = 'Packaging';
export const ON_DELIVERY = 'On Delivery';
export const DELIVERED = 'Delivered';
export const RECEIVED = 'Received';
export const PENDING = 'Payment Pending';
export const INITIATE_HUMM = 'Initiate Humm Process';
export const CANCELLED = 'Cancelled';
export const ORDERED_humm = 'Ordered - Humm';
export const REFUNDED = 'Refunded';
export const EMAIL_SEND = 'Email Send';
export const PROCESSING = 'Processing';
export const ORDER_STATUS_TYPE = [
  { id: ORDERED, name: 'Ordered' },
  { id: PACKAGING, name: 'Packaging' },
  { id: ON_DELIVERY, name: 'On Delivery' },
  { id: DELIVERED, name: 'Delivered' },
  { id: RECEIVED, name: 'Received' },
  { id: PENDING, name: 'Payment Pending' },
  { id: INITIATE_HUMM, name: 'Initiate Humm Process' },
  { id: CANCELLED, name: 'Cancelled' },
  { id: ORDERED_humm, name: 'Ordered - Humm' },
  { id: REFUNDED, name: 'Refunded' },
];

export const SCHOOL_ORDER_QUOTATION_STATUS_TYPE = [
  { id: EMAIL_SEND, name: 'Email Send' },
  { id: PROCESSING, name: 'Processing' },
  { id: CANCELLED, name: 'Cancelled' },
  { id: DELIVERED, name: 'Delivered' },
];
export const ORDER_STATUS_CLASS_NAMES = {
  [ORDERED]: '',
  [PACKAGING]: '',
  [ON_DELIVERY]: 'bg-primary-label',
  [DELIVERED]: 'bg-secondary-label',
  [RECEIVED]: 'bg-secondary-label',
  [PENDING]: '',
};

export const INSTALLMENT_ORDER_STATUS = {
  paid: 'success',
  not_paid: 'warning',
  overdue: 'danger',
};
