import axios from './axios';
import { store } from 'store/index';
import { setUserData } from 'reducers/userSlice';
import { REACT_APP_API_URL, REACT_APP_CURRENT_BASE_URL } from 'constants/config';
import { ls } from './ls-crypto';
import Log from 'lib/Log';

const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  withCredentials: true,
});

instance.interceptors.request.use(
  async (req) => {
    const {
      user: { csrfToken },
    } = store.getState();

    if (csrfToken) {
      req.headers['x-csrf-token'] = csrfToken;
      return req;
    }

    if (!csrfToken) {
      try {
        const {
          data: { data },
        } = await axios.get(`${REACT_APP_API_URL}/csrf-token`, {
          withCredentials: true,
        });
        store.dispatch(setUserData({ csrfToken: data }));
        req.headers['x-csrf-token'] = data;
        return req;
      } catch (err) {
        Log.error('Error fetching token:', err);
      }
    }
  },
  (err) => {
    return Promise.reject(err);
  },
);

instance.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    Log.error('error --------> ', err);
    if (err.response.status === 401) {
      ls.clear();
      window.location.href = '/';
    } else if (err.response.data.message === "store doesn't exist") {
      window.location.href = REACT_APP_CURRENT_BASE_URL;
    }
    return Promise.reject(err);
  },
);

export default instance;
