import React from 'react';
import moment from 'moment';

const DateTemplate = ({ value, data, tableManager }) => {
  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;

  //const classNames = ('rgt-cell-inner rgt-text-truncate ' + (additionalProps.className || '')).trim();

  return (
    <>
      <span className="grid-text-ellipsis fw-bold ps-20" title={value}>
        {moment(value).utc().format('YYYY-MM-DD, h:mm a')}
      </span>
    </>
  );
};

export default DateTemplate;
