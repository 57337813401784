import { useState, useRef, useEffect } from 'react';
import { GridTable, SecondaryHeader, SecondaryHeaderButtonWrap, SecondaryHeaderInfo, SecondaryHeaderSearchField, Spinner } from 'react-myoneui';
import TextTemplate from 'components/grid-table/TextTemplate';
import PaginationTemplate from 'components/grid-table/PaginationTemplate';
import { approveRefundRequests, getRefundRequests } from 'api/admin';
import { ConfirmBox } from 'components/ConfirmBox';
import FilterOffcanvas from 'modules/Admin/schoolClass/FilterOfCanvas';
import { alertDangerIcon, alertTickIcon, clearBtn, crossIcon } from 'constants/icons';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AlertNotification from 'components/alertNotification/alertNotification';
import { REACT_APP_EXPORT_ALERT_DISPLAY_TIME } from 'constants/config';
import ViewRefundOffcanvas from './ViewRefundOffCanvas';
import { getCurrency } from 'utils/currency';
// import Button from 'react-myoneui/lib/Button';
import DateTemplate from 'components/grid-table/DateTemplate';
import DropdownTemplate from './DropdownTemplate';
import Log from 'lib/Log';
function RefundRequest() {
  const { pathname } = useLocation();

  const columnData = [
    // {
    //   id: 'checkbox',
    //   pinned: true,
    //   className: 'selectAll section-grid-border-line',
    //   width: '55px',
    //   minResizeWidth: 0,
    //   maxResizeWidth: null,
    //   resizable: false,
    //   visible: true,
    // },
    {
      id: 1,
      field: 'requested_by',
      visible: true,
      label: 'Requested By',
      width: '.3fr',
      sortable: true,
      className: 'grid-table-title',
      cellRenderer: TextTemplate,
    },
    {
      id: 2,
      field: 'order_id',
      visible: true,
      label: 'Order Id',
      width: '.3fr',
      sortable: false,
      className: 'grid-table-title',
      cellRenderer: TextTemplate,
    },

    {
      id: 3,
      field: 'amount',
      visible: true,
      label: 'Amount to Refund',
      width: '.3fr',
      sortable: false,
      className: 'grid-table-title',
      cellRenderer: TextTemplate,
    },
    {
      id: 4,
      field: 'type',
      visible: true,
      label: 'Refund Type',
      width: '.3fr',
      sortable: false,
      className: 'grid-table-title',
      cellRenderer: TextTemplate,
    },

    {
      id: 5,
      field: 'status',
      visible: true,
      label: 'Status',
      width: '.3fr',
      sortable: false,
      className: 'grid-table-title',
      cellRenderer: TextTemplate,
    },

    {
      id: 6,
      field: 'refund_status',
      visible: true,
      label: 'Refund Status',
      width: '.3fr',
      sortable: false,
      className: 'grid-table-title',
      cellRenderer: TextTemplate,
    },
    {
      id: 7,
      field: 'createdAt',
      visible: true,
      label: 'Requested At',
      width: '.3fr',
      sortable: false,
      className: 'grid-table-title',
      cellRenderer: DateTemplate,
    },

    {
      id: 8,
      visible: true,
      field: 'action',
      label: '',
      width: '.5fr',
      sortable: true,
      cellRenderer: ({ data }) => {
        return (
          <DropdownTemplate
            enableAction={data.status === 'pending'}
            handleView={() => setShowView({ view: true, data })}
            handleApprove={() => {
              setSelectedRefunds([data.id]);
              setConfirmBoxStatus({ status: true, for: 'Approve' });
            }}
            handleDisApprove={() => {
              setSelectedRefunds([data.id]);
              setConfirmBoxStatus({ status: true, for: 'Disapprove' });
            }}
          />
        );
      },
    },
  ];

  const timerRef = useRef(null);
  const tableManager = useRef(null);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState('desc');
  const [sortBy, setSortBy] = useState('createdAt');
  const [activeColId, setActiveColId] = useState('checkbox');
  const [confirmBoxStatus, setConfirmBoxStatus] = useState({ status: false, for: '' });
  const [showView, setShowView] = useState({ view: false, data: {} });
  const [displayResetFilter, setDisplayResetFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [resetFilter, setResetFilter] = useState(false);
  const [successAlertStatus, setSuccessAlertStatus] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [selectedRefunds, setSelectedRefunds] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);

  useEffect(() => {
    if (alertMsg) {
      const timer = setTimeout(() => {
        setAlertMsg(null);
      }, REACT_APP_EXPORT_ALERT_DISPLAY_TIME);

      return () => clearTimeout(timer);
    }
  }, [alertMsg]);

  const setTableManager = (tm) => (tableManager.current = tm);

  const {
    user: {
      role: { privileges },
    },
  } = useSelector((state) => state.user);

  useEffect(() => {
    let query = {};
    fetchRefundRequests(query);
  }, [searchText, perPage, currentPage, sort, sortBy, pathname]);

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setSearchText(value);
    }, 500);
  };

  const fetchRefundRequests = async (filterData, reset = false) => {
    try {
      setLoading(true);
      setTotalData(0);
      const payload = { perPage: perPage, currentPage: currentPage, search: searchText.trim(), sort: sort, sortBy: sortBy, ...filterData };

      const response = await getRefundRequests(payload);
      if (response) {
        setData(
          response?.data?.data?.refundRequestList?.map((refund) => ({
            ...refund,
            requested_by: `${refund.request_by.first_name} ${refund.request_by.last_name}`,
            order_id: refund.order.order_id,
            type: refund.type,
            amount: `${getCurrency(refund.order.school.currency)} ${refund.amount}`,
            refund_status: refund.refund_status ?? '-----',
            status: refund.status,
          })),
        );
        setTotalData(response?.data?.data?.meta?.refundRequestCount);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onClear = (e) => {
    setSearchText('');
    setSearch('');
  };

  const sortHandler = (sortData) => {
    setSort(sortData.isAsc ? 'asc' : 'desc');
    if (sortData.colId) {
      const sortCol = columnData.find((colData) => colData.id === sortData.colId);
      setActiveColId(sortCol.id);
      setSortBy(sortCol.field);
    } else {
      setActiveColId('checkbox');
      setSortBy('createdAt');
      setSort('desc');
    }
    setPerPage(10);
    setCurrentPage(1);
  };

  const approveRefunds = async (status) => {
    if (!requestLoading) {
      try {
        setRequestLoading(true);
        const { data } = await approveRefundRequests({ refundIds: selectedRefunds, status });
        if (data.success) {
          await fetchRefundRequests();
        }
      } catch (err) {
        Log.error(err);
      } finally {
        setConfirmBoxStatus({ status: false, for: '' });
        setRequestLoading(false);
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        {alertMsg ? (
          <div className="alert-wrapper position-fixed top-0 start-50 translate-middle-x">
            <AlertNotification
              className={`${successAlertStatus ? 'alert-success' : 'alert-danger'} preview-notify`}
              title={successAlertStatus ? 'Request Success' : 'Request Failed'}
              desc={alertMsg}
              icon={successAlertStatus ? alertTickIcon : alertDangerIcon}
              closeBtn={crossIcon}
              onclick={() => {
                setSuccessAlertStatus(false);
                setAlertMsg(null);
              }}
            />
          </div>
        ) : null}

        <SecondaryHeader>
          <SecondaryHeaderInfo SecondaryHeaderTitle="Refund Request" SecondaryHeaderText={totalData + ' request'} SecondaryText />
          <SecondaryHeaderSearchField placeholder="Search based on Requested By..." value={search} onChange={onSearchChange} onClear={onClear} />
          {/* <SecondaryHeaderButtonWrap>
            <Button variant="primary" className="export" onClick={() => setConfirmBoxStatus(true)} disabled={!selectedRefunds.length || requestLoading}>
              Approve
            </Button>
          </SecondaryHeaderButtonWrap> */}
          <span></span>
        </SecondaryHeader>

        <div className="filter-block">
          <FilterOffcanvas
            triggerTitle="Show Filters"
            showDateRange={true}
            showSchool={false}
            applyFilter={(data) => {
              let filter = {};

              if (data?.fromDate) {
                filter.fromDate = data.fromDate;
              }
              if (data?.toDate) {
                filter.toDate = data.toDate;
              }

              setFilter(filter);

              if (Object.entries(filter).length > 0) {
                setDisplayResetFilter(true);
                fetchRefundRequests(filter);
              } else {
                setDisplayResetFilter(false);
                fetchRefundRequests(filter, true);
              }
            }}
            updateResetFilter={() => {
              setResetFilter(false);
            }}
            resetFilter={resetFilter}
          />
          {displayResetFilter && (
            <span
              className="clear-filter"
              onClick={() => {
                setFilter({});
                setResetFilter(true);
                setDisplayResetFilter(false);
              }}
            >
              {clearBtn}
              Clear Filters
            </span>
          )}
        </div>

        <div className="content-block px-0 py-2">
          {loading && (
            <div className="spinner-wrapper">
              <Spinner animation="border" />
            </div>
          )}
          {!loading && (
            <GridTable
              className="ellipsis-text-grid rgt-border-line-none"
              searchText={searchText}
              highlightSearch={false}
              columns={columnData}
              onRowsRequest={() => {
                return {
                  rows: data,
                  totalRows: totalData,
                };
              }}
              // onSelectedRowsChange={(id) => setSelectedRefunds(id)}
              // onRowClick={(a) => console.log('onRowClick:', a)}
              onLoad={setTableManager}
              enableColumnsReorder={false}
              page={currentPage}
              pageSize={perPage}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(data) => {
                setCurrentPage(1);
                return setPerPage(data);
              }}
              onSortChange={(sortData) => sortHandler(sortData)}
              sort={{ colId: activeColId, isAsc: sort === 'asc' ? true : false }}
              components={{ Footer: PaginationTemplate, NoResults: () => 'No Any Refund Request Found' }}
            />
          )}
        </div>

        {showView?.view && (
          <ViewRefundOffcanvas
            data={showView?.data}
            reset={() => {
              setShowView({ view: false, data: {} });
            }}
            show={showView?.view}
          />
        )}

        {confirmBoxStatus.status && (
          <ConfirmBox
            show={confirmBoxStatus.status}
            question={`Are you sure you want to ${confirmBoxStatus.for} Refund?`}
            cancelText="Cancel"
            okText={confirmBoxStatus.for}
            onHide={() => setConfirmBoxStatus({ status: false, for: '' })}
            onOk={() => {}}
            okHandler={() => approveRefunds(confirmBoxStatus.for.toLocaleLowerCase())}
            loading={requestLoading}
          />
        )}
      </div>
    </>
  );
}

export default RefundRequest;
