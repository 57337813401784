import { useState } from 'react';
import Button from 'react-myoneui/lib/Button';
import Toast from 'react-myoneui/lib/Toast';
import ToastContainer from 'react-myoneui/lib/ToastContainer';
import ToastInfo from 'react-myoneui/lib/ToastInfo';

function SnackBar({ applyFilter = () => {} }) {
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);

  return (
    <>
      <Button
        onClick={() => {
          toggleShowA();
          applyFilter();
        }}
      >
        Apply Filter
      </Button>
      <ToastContainer position="bottom-center" className="p-3 position-fixed">
        <Toast variant="primary" show={showA} onClose={toggleShowA} delay={3000} autohide>
          <Toast.Body>
            <ToastInfo toastDescription="New apply filter has been created successfully" />
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default SnackBar;
