const mapCurrency = {
  EUR: '€',
  GBP: '£',
  USD: '$',
};

export const getCurrency = (currency = 'EUR') => {
  return mapCurrency[currency];
};

export const currencies = () =>
  Object.keys(mapCurrency).map((curr) => {
    return { id: curr, name: `${curr} - ${getCurrency(curr)}` };
  });

export const getPriceOnly = (priceWithCurrency) => {
  const curr = Object.values(mapCurrency).find((curr) => priceWithCurrency.includes(curr));
  if (curr) {
    return Number(priceWithCurrency.replace(curr, '').trim());
  }
  return curr;
};
