import * as React from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const pageSizes = [10, 20, 50, 100];

function RowPerPageDropdown({ items = pageSizes, value, onChange }) {
  return (
    <Dropdown className="dropdown-select" onSelect={onChange}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <p className="page-count">{value}</p>
        <div className="caret-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="6.119" height="11.658" viewBox="0 0 6.119 11.658">
            <path
              id="Path_21616"
              data-name="Path 21616"
              d="M0,0,4.42,3.71,8.84,0"
              transform="translate(1.409 10.249) rotate(-90)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map((item, i) => (
          <Dropdown.Item key={i} eventKey={item} active={value === item}>
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default RowPerPageDropdown;
