// custom-label | className | bg-default-label | bg-primary-label | bg-secondary-label

export default function CustomLabel(props) {
  const { labelText, title } = props;
  return (
    <>
      {labelText && (
        <div className={`custom-label ${props.className || 'bg-default-label'}`} title={title}>
          {labelText}
        </div>
      )}
    </>
  );
}
