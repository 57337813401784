import React from 'react';
import { Modal, Button } from 'react-myoneui';
import { Spinner } from 'react-myoneui';
export const ConfirmBox = (props) => {
  const { show, info, okText, question, cancelText, onOk, onHide, okHandler, loading } = props;

  return (
    <Modal show={show} onHide={onHide} contentClassName="bg-transparent" centered>
      {question && (
        <Modal.Header>
          <Modal.Title>{question}</Modal.Title>
        </Modal.Header>
      )}
      {info && (
        <Modal.Body>
          <p>{info}</p>
        </Modal.Body>
      )}
      <Modal.Footer>
        <>
          {onHide && (
            <Button variant="glitter" onClick={onHide}>
              {cancelText ? cancelText : ' No, Cancel'}
            </Button>
          )}
          {onOk && (
            <Button variant="primary" onClick={okHandler}>
              <div className="d-flex">
                {loading && (
                  <span>
                    <Spinner animation="border" size="sm" /> &nbsp; &nbsp;
                  </span>
                )}

                {okText ? okText : ' Yes, Confirm'}
              </div>
            </Button>
          )}
        </>
      </Modal.Footer>
    </Modal>
  );
};
