import { useState, forwardRef, Children, useEffect, useRef } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { caretIcon } from 'constants/icons';
import CreateNewItems from 'modules/Admin/faq/CreateNewItems';
import Log from 'lib/Log';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(({ children, style, className, value, 'aria-labelledby': labeledBy }, ref) => {
  // const [value, setValue] = useState('');

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props?.children?.toString().toLowerCase().includes(value))}</ul>
    </div>
  );
});

const sampleItems = [];

function DropdownCommon({
  isFromBillingUserModel = false,
  label,
  required = false,
  className,
  items = sampleItems,
  defaultValue = '',
  onChange = () => {},
  disabled = false,
  dropCode = false,
  canSearch = true,
  readOnly = false,
  name,
  createNewItems,
  onAdd = () => {},
  onSearch = () => {},
}) {
  const txtBox = useRef(null);
  const [menuValue, setMenuValue] = useState('');
  const [show, setShow] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isValid, setIsValid] = useState(false);
  // const [selected, setSelected] = useState({});
  // useEffect(() => {
  //   if (defaultValue) {
  //     setSelected(defaultValue);
  //   }
  // }, []);

  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (!show && selected && selected !== '') {
      const isValid = items.find(({ name }) => name === selected);
      if (!isValid) {
        setSelected('');
        if (!required) {
          setHasStarted(false);
        }
      }
    }
  }, [show]);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue?.name);
    } else {
      setSelected('');
    }
  }, [defaultValue]);

  const onSelect = (index) => {
    // setSelected(items[index]);
    if (items[index]?.name && items[index]?.name !== '') {
      setSelected(items[index].name);
      let obj = items[index];
      if (name) {
        obj.attr_name = name;
      }
      setIsValid(true);
      setMenuValue('');
      onChange(obj);
      setTotalCount(1);
    }
  };
  const onToggle = (show, meta) => {
    setShow(show);
    if (!show) {
      setMenuValue('');
    }
  };

  const onInputChange = (e, from) => {
    try {
      if (!readOnly) {
        const val = e.target.value ? e.target.value.trim().toLowerCase() : '';
        const total = items.filter((item) => {
          if (item?.name && item?.name !== '') {
            let newval = val.replace('+', '').toString();
            //  console.log(item);
            let itemname = item.name.replace('+', '').toString();

            let patt = new RegExp('^' + newval, 'g');
            // console.log(itemname + ':' + newval);
            return patt.test(itemname);
            //return item.name.search(val) > 0;
            // console.log(item.name.toLowerCase());
            //return item.name.toLowerCase().includes(val);
          }
        }).length;
        setTotalCount(total);
        setHasStarted(true);
        if (from === 'addedValue') {
          setAddedValue(e.target.value);
        } else {
          setSelected(e.target.value);
        }
        onSearch(e.target.value);

        let obj = items.find((item) => item.name && item.name !== '' && item.name === e.target.value);
        if (obj) {
          let index = items.indexOf(obj);
          onSelect(index);
          if (e.target.value.length > 0 && index) {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
          // let dataObj = items[index];
          // dataObj.attr_name = name;
          // onChange(e);
        } else {
          setIsValid(false);
        }
        setMenuValue(val);
        setShow(true);
      }
    } catch (error) {
      Log.error(error);
    }
  };
  // const onInputKeyUp = (e) => {
  //   const val = e.target.value ? e.target.value.trim().toLowerCase() : '';
  //   if (e.key === 'Enter' && val) {
  //     const matchedIndex = items.findIndex((item) => item.toLowerCase().includes(val));
  //     if (matchedIndex > -1) {
  //       const selected = items[matchedIndex];
  //       setSelected(selected);
  //       onChange(selected);
  //     }
  //   }
  // };

  const [addedValue, setAddedValue] = useState('');
  return (
    <Dropdown className={`dropdown-select ${className || ''}`} onSelect={onSelect} show={show} onToggle={onToggle} disabled={disabled} align="end">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <FloatingLabel
          controlId="floatingSelection"
          label={
            !required ? (
              label
            ) : (
              <>
                {label} <span className="text-danger">*</span>
              </>
            )
          }
          className="w-100"
        >
          <Form.Control
            type="text"
            readOnly={readOnly}
            style={{ background: readOnly ? 'inherit' : 'inherit' }}
            placeholder="Events"
            autoComplete="off"
            name={name}
            onChange={(e) => onInputChange(e, addedValue.length ? 'addedValue' : 'selected')}
            // onKeyDown={onInputChange}
            // value={dropCode ? (selected?.code || '') + `${selected?.code && selected?.name ? ' - ' : ''}` + (selected?.name || '') : selected?.name}
            // value={selected?.name}
            value={addedValue.length ? addedValue : selected}
            // value={selected}
            className={`${canSearch ? '' : 'input-pointer-event-none'} ${hasStarted && totalCount === 0 ? 'border border-danger' : ''}`}
          />
          <div className="caret-icon">{caretIcon}</div>
          {/* {hasStarted && totalCount === 0 && <span className="invalid-feedback d-block mb-28">Invalid {label ? label : 'value'}</span>} */}
          {/* {hasStarted ? 'Started' : 'NotStarted'}: {required ? 'Required' : 'Not Required'} : {isValid ? 'valid' : 'invalid'} */}
          {required && hasStarted && !isValid && <span className="invalid-feedback d-block mb-28">Required*</span>}
        </FloatingLabel>
      </Dropdown.Toggle>

      <Dropdown.Menu value={menuValue} as={CustomMenu}>
        {items.map(
          (item, i) =>
            item?.name && (
              <Dropdown.Item key={i} eventKey={i} active={selected === item}>
                {dropCode && <span className="fw-bold">{item?.code || item?.id} - </span>} {item?.name}
              </Dropdown.Item>
            ),
        )}
      </Dropdown.Menu>

      {createNewItems && (
        <Dropdown.Menu value={menuValue} as={CustomMenu}>
          <div className="create-new-item-scroll">
            {items.map(
              (item, i) =>
                item?.name && (
                  <Dropdown.Item key={i} eventKey={i} active={selected === item}>
                    {dropCode && <span className="fw-bold">{item?.code || item?.id} - </span>} {item?.name}
                  </Dropdown.Item>
                ),
            )}
          </div>
          <CreateNewItems onAdd={onAdd} setAddedValue={setAddedValue} />
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}

export default DropdownCommon;
