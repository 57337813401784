import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { getPlainTextFromHtmlText } from 'lib/common';

const TextTemplate = ({ value, data, tableManager }) => {
  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;

  // const classNames = ('rgt-cell-inner rgt-text-truncate ' + (additionalProps.className || '')).trim();

  return (
    <>
      <span className="grid-text-ellipsis fw-bold ps-20" title={getPlainTextFromHtmlText(value)} dangerouslySetInnerHTML={{ __html: sanitizeHtml(value) }} />
    </>
  );
};

export default TextTemplate;
