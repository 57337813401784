import CustomLabel from 'components/school-component/CustomLabel';
function OrderItem(props) {
  const { itemName, supplierName, imageUrl, mandatory, is_Refunded } = props;
  return (
    <div className={`order-item ${props.className || ''}`}>
      {imageUrl && (
        <div className="img-wrap">
          <img src={imageUrl} alt="Item" loading="lazy" />
        </div>
      )}
      <div className="order-item-info">
        {itemName && <h4>{itemName}</h4>}
        {supplierName && (
          <p>
            {' '}
            Supplier: <span>{supplierName}</span>
          </p>
        )}
      </div>
      {mandatory && <div className="mandatory-wrap">{mandatory}</div>}
      {is_Refunded && <CustomLabel labelText="Refunded" className="custom-label bg-default-label" />}
    </div>
  );
}

export default OrderItem;
