import * as React from 'react';
import { Button, Offcanvas } from 'react-myoneui';
import { ORDER_STATUS_CLASS_NAMES } from 'constants/orderStatus';
import CustomLabel from 'components/school-component/CustomLabel';
import { displayMonth } from 'utils/formatTime';
import OrderItem from 'modules/schoolManagement/orderDetail/components/OrderItem';
import { getCurrency } from 'utils/currency';
import { getPlainTextFromHtmlText } from 'lib/common';
import { asteriskIcon } from 'constants/icons';

function ViewRefundOffcanvas({ data, reset, show }) {
  const {
    requested_by,
    type,
    amount,
    status,
    refund_status,
    order: {
      school: { currency, logo },
      package: { name },
      order_id,
    },
    items,
    balance_transaction,
    reason,
    createdAt,
  } = data;

  const handleClose = () => {
    reset();
  };
  const offCanvasKeyDown = (e) => e.stopPropagation();

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end" onKeyDown={offCanvasKeyDown} className="order-view-modal">
        <Offcanvas.Header closeButton icon="close" backButton={true} className="pb-0">
          <Offcanvas.Title>
            Refund Order Details
            <p className="help-text w-100">Refund Order Details requested by {requested_by}</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="order-detail-header">
            <div className="img-wrap">
              <img src={logo} alt="School logo" loading="lazy" />
            </div>
            <div className="info-wrap">
              <h4>{name}</h4>
              <span className="order-number">Order number: {order_id}</span>
              <CustomLabel labelText={status} className={ORDER_STATUS_CLASS_NAMES[status] || ''} />
            </div>
          </div>

          <div className="page-detail-holder">
            <div className="page-content-detail">
              <div className="page-detail-wrap">
                <h4 className="pade-detail-title">Refund Package Details</h4>
                <ul className="students-info-list list-unstyled">
                  <li>
                    <h4>Applied Date</h4>
                    <span>{displayMonth(createdAt)}</span>
                  </li>
                  <li>
                    <h4>Refund Type</h4>
                    <span>{type}</span>
                  </li>
                  <li>
                    <h4>Amount</h4>
                    <span>{amount}</span>
                  </li>
                  <li>
                    <h4>Refund Status:</h4>
                    <span>{refund_status}</span>
                  </li>
                  {balance_transaction ? (
                    <li>
                      <h4>Balance Transaction</h4>
                      <span>
                        {getCurrency(currency)} {balance_transaction}
                      </span>
                    </li>
                  ) : null}
                  <li>
                    <h4>Reason</h4>
                    <span>{getPlainTextFromHtmlText(reason)}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="page-items-detail">
              <h4 className="pade-detail-title mb-3">Requested Items</h4>
              {items?.map((item) => (
                <OrderItem key={item?._id} className="mb-3" itemName={item?.name} imageUrl={item?.thumbnail} mandatory={item?.is_mandatory ? asteriskIcon : ''} />
              ))}
            </div>
          </div>
        </Offcanvas.Body>

        <Offcanvas.Footer>
          <Button variant="primary" onClick={handleClose}>
            <div className="d-flex align-items-center gap-2">Close</div>
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default ViewRefundOffcanvas;
