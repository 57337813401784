export const INSTALLMENT_PAYMENT = 'installment';
export const ONE_TIME_PAYMENT = 'one_time_payment';
export const PAYMENT_PLAN = 'payment_plan';
export const MONTHLY_PAYMENT_PLAN = 'monthly_payment';

export const PAYMENT_TYPE_MAP = {
  [INSTALLMENT_PAYMENT]: 'Installment',
  [ONE_TIME_PAYMENT]: 'One Time Payment',
  [PAYMENT_PLAN]: 'Olive Payment Plan',
  [MONTHLY_PAYMENT_PLAN]: 'Humm Finance Payment',
};
