import { creditIcon, hummCreditIcon } from 'constants/icons';
import Log from 'lib/Log';

export function setFavicon(favicon) {
  if (!favicon) {
    return;
  }
  document.head || (document.head = document.getElementsByTagName('head')[0]);
  var n = document.createElement('link');
  var d = document.createElement('link');
  var t = document.getElementById('xlink');
  var a = document.getElementById('plink');
  d.id = 'plink';
  d.rel = 'shortcut icon';
  d.type = 'image/png';
  d.href = favicon;
  n.id = 'xlink';
  n.rel = 'icon';
  n.type = 'image/x-icon';
  n.href = favicon;
  if (t) {
    document.head.removeChild(t);
  }
  if (a) {
    document.head.removeChild(a);
  }
  document.head.appendChild(n);
  document.head.appendChild(d);
}

export function getLangFromCache() {
  try {
    return localStorage.getItem('curr_lang');
  } catch (e) {
    Log.error(e);
  }
  return null;
}

export function setLangInCache(lang) {
  try {
    localStorage.setItem('curr_lang', lang);
  } catch (e) {
    Log.error(e);
  }
}

export const formatInstallmentName = (name) => {
  const [first_name, index] = name.split('_');
  return `${first_name[0].toUpperCase()}${first_name.slice(1)} ${index ? index : ''}`;
};

export const downloadCSV = async (fileName, data) => {
  const blob = new Blob([data], { type: 'octet-stream' });
  const href = URL.createObjectURL(blob);
  const a = Object.assign(document.createElement('a'), {
    href,
    style: 'display:none',
    download: fileName,
  });
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(href);
  a.remove();
};

export const mapCreditIcon = {
  payment_plan: creditIcon,
  monthly_payment: hummCreditIcon,
};
