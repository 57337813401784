import { React } from 'react';
import { forwardRef } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import { dotsIcon } from 'constants/icons';

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    className="btn btn-icon rounded-circle"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

function GridDropdown({ children }) {
  return (
    <>
      <Dropdown className="dropdown-select position-static grid-dropdown-list" align="end">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {dotsIcon}
        </Dropdown.Toggle>
        {children}
      </Dropdown>
    </>
  );
}
export default GridDropdown;
